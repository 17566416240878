import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatButton } from "blocks-react/bedrock/components/Button/Button";
import { CatHeading } from "blocks-react/bedrock/components/Heading/Heading";
import { CatIconAdd } from "blocks-react/bedrock/components/Icons/Add/Add";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectSummary from "../../components/Projects/ProjectSummary";
import { CaasRoute } from "../../routes";
import { PageEvents } from "../../shared/pageEvents";
import { hasCreateProjectPermission } from "../../shared/validateUserPermission";
import useAppConfigStore from "../../store/useAppConfigStore";
import useGlobalStore from "../../store/useGlobalStore";
import ProjectSubHeader from "./ProjectSubHeader";
const Projects = () => {
    const [selectedView, setSelectedView] = useState("tile");
    const [filter, setFilter] = useState("All");
    const navigate = useNavigate();
    const { appConfig } = useAppConfigStore((state) => ({
        appConfig: state.appConfig
    }));
    const { isAgreementAccepted, userEntitlements } = useGlobalStore((state) => ({
        isAgreementAccepted: state.isAgreementAccepted,
        userEntitlements: state.userEntitlements
    }));
    const updateViewType = (view) => {
        if (view !== selectedView) {
            setSelectedView(view);
        }
        return null;
    };
    const filters = ["All", "Active", "Pending", "Closed"];
    const activeIndex = filters.indexOf(filter);
    const dataLayer = (window["dataLayer"] = window["dataLayer"] || []);
    useEffect(() => {
        const count = window?.sessionStorage?.getItem("customerSearchModelPageCount");
        if (count && localStorage.getItem("partyNumber") && userEntitlements?.primaryPartyType && isAgreementAccepted) {
            dataLayer.push({
                event: PageEvents.PAGE_VIEW,
                page_location: window.location.href, // setting the full url path
                page_title: "Projects Home page"
            });
        }
        if (!count && localStorage.getItem("partyNumber")) {
            window.sessionStorage.setItem("customerSearchModelPageCount", "0");
        }
    }, []);
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex p-4", children: [_jsx("div", { className: "mr-auto", children: _jsx(CatHeading, { "data-test": "projects-home-title", variant: "title", children: "Projects Home" }) }), hasCreateProjectPermission(userEntitlements) && appConfig?.release_1_1?.createProject && (_jsx(CatButton, { "data-test": "New-Project-Button", onClick: () => navigate(CaasRoute.CreateNewProject), size: "sm", variant: "primary", children: _jsxs(React.Fragment, { children: ["New Project ", _jsx(CatIconAdd, { slot: "after" })] }, ".0") }))] }), _jsx("div", { className: "mb-4", children: _jsx("hr", {}) }), _jsx(ProjectSubHeader, { activeIndex: activeIndex, "data-test": "Project-Subheader", onFilter: setFilter, selectedView: selectedView, updateView: updateViewType }), _jsx(ProjectSummary, { "data-test": "Project-Summary", filter: filter, selectedView: selectedView })] }));
};
export default Projects;
