import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CatIconGrid } from "blocks-react/bedrock/components/Icons/Grid/Grid";
import { CatIconList } from "blocks-react/bedrock/components/Icons/List/List";
import { CatSegmentedControl } from "blocks-react/bedrock/components/SegmentedControl/SegmentedControl";
import { CatSegmentedControlItem } from "blocks-react/bedrock/components/SegmentedControlItem/SegmentedControlItem";
import { useEffect, useState } from "react";
import CustomerSearch from "../../components/CustomerSearch/CustomerSearch";
import useGlobalStore from "../../store/useGlobalStore";
const ProjectSubHeader = (props) => {
    const CUSTOMER_UCID_SELECTED = "partyNumber";
    const { customerName, isLoggedInAsCAT, setUserPartyNumber, userPartyNumber } = useGlobalStore((state) => ({
        customerName: state.customerName,
        isLoggedInAsCAT: state.isLoggedInAsCAT,
        setUserPartyNumber: state.setUserPartyNumber,
        userPartyNumber: state.userPartyNumber
    }));
    function extractPartyNumberFromSelectedCustomer(partyNumber) {
        const match = partyNumber.match(/\d+/);
        return match ? match[0] : partyNumber;
    }
    const [partyNumber, setPartyNumber] = useState(localStorage.getItem(CUSTOMER_UCID_SELECTED) || userPartyNumber);
    useEffect(() => {
        if (customerName) {
            setUserPartyNumber(extractPartyNumberFromSelectedCustomer(partyNumber));
            localStorage.setItem(CUSTOMER_UCID_SELECTED, partyNumber);
        }
    }, [partyNumber]);
    return (_jsxs("div", { className: "flex justify-between items-center", "data-test": "sub-header", children: [_jsxs("div", { className: "inline-flex cat-u-margin-md", "data-test": "project-status-container", children: [_jsx(CatSegmentedControl, { activeIndex: props.activeIndex, children: _jsxs(_Fragment, { children: [_jsx(CatSegmentedControlItem, { "data-test": "project-status-all", onClick: () => props.onFilter("All"), children: "All" }), _jsx(CatSegmentedControlItem, { "data-test": "project-status-active", onClick: () => props.onFilter("Active"), children: "Active" }), _jsx(CatSegmentedControlItem, { "data-test": "project-status-pending", onClick: () => props.onFilter("Pending"), children: "Pending" }), _jsx(CatSegmentedControlItem, { "data-test": "project-status-closed", onClick: () => props.onFilter("Closed"), children: "Closed" })] }) }), isLoggedInAsCAT && (_jsx("div", { className: "ml-1", children: _jsx(CustomerSearch, { "data-test": "project-sub-header-customer-search", partyNumber: partyNumber, setPartyNumber: setPartyNumber, size: "sm" }) }))] }), _jsxs("div", { className: "flex space-x-6 mr-4 cursor-pointer", "data-test": "project-view-icon-containter", children: [_jsx(CatIconGrid, { className: `p-1 ${props.selectedView === "tile" ? "bg-zinc-400 opacity-75 selected" : ""}`, color: "content-strong", "data-test": "tile-view", onClick: () => {
                            props.updateView("tile");
                        }, size: "lg" }), _jsx(CatIconList, { className: `p-1 ${props.selectedView === "list" ? "bg-zinc-400 opacity-75 selected" : ""}`, color: "content-strong", "data-test": "list-view", onClick: () => {
                            props.updateView("list");
                        }, size: "lg" })] })] }));
};
export default ProjectSubHeader;
